import React, { useState, useEffect } from 'react';
import axiosInstance from '../AxiosInstance';
import { BASE_URL } from '../config';
import './SettingsPage.css';

const SettingsPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await axiosInstance.get(`${BASE_URL}/api/settings`);
            setUsername(response.data.username);
            setPassword(response.data.password);
            setCountry(response.data.country);
            setCity(response.data.city);
            setStreet(response.data.street);
            setZipCode(response.data.zipCode);
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleRefIdChange = (event) => {
        setUsername(event.target.value);
    };

    const handleKeyChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
    };

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
    };

    const handleFirstNameChange = (event) => {
        setName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSaveSettings = async () => {
        try {
            const response = await axiosInstance.post(`${BASE_URL}/api/settings/save`, {
                username,
                password,
                country,
                city,
                street,
                zipCode,
                firstName,
                lastName
            });
            if (response.data && response.data.isError === false) {
                showSuccess('Settings saved successfully.');
                setErrors([]); // Clear any previous errors
            } else {
                handleApiError(response);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleApiError = (error) => {
        if (error.data && error.data.status >= 400 && error.data.status < 500) {
            showError('Bad request error');
        } else if (error && error.data && error.data.isError) {
            showError(error.data.errorMessage);
        } else {
            showError('An unexpected error occurred. Please try again later.');
        }
    };

    const showSuccess = (message) => {
        const id = new Date().getTime();
        setSuccessMessages((prevMessages) => [...prevMessages, { id, message }]);
        setTimeout(() => {
            setSuccessMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
        }, 5000); // Hide success message after 5 seconds
    };

    const showError = (message) => {
        const id = new Date().getTime();
        setErrors((prevErrors) => [...prevErrors, { id, message }]);
        setTimeout(() => {
            setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
        }, 5000); // Hide error message after 5 seconds
    };

    return (
        <div className="settings-container">
            <div className="settings-content">
                <h2>Settings</h2>
                <form>
                    <div className="input-group">
                        <label>REFID:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={username}
                            onChange={handleRefIdChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>KEY:</label>
                        <input
                            type="password"
                            className="input-field"
                            value={password}
                            onChange={handleKeyChange}
                        />
                    </div>
                </form>
            </div>

            <div className="address-sidebar">
                <h2> Address</h2>
                <form>
                    <div className="input-group">
                        <label>Country:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={country}
                            onChange={handleCountryChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>City:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={city}
                            onChange={handleCityChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Street:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={street}
                            onChange={handleStreetChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Zip Code:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={zipCode}
                            onChange={handleZipCodeChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>First Name:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={firstName}
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div className="input-group">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            className="input-field"
                            value={lastName}
                            onChange={handleLastNameChange}
                        />
                    </div>
                </form>
            </div>

            <div className="button-container">
                <button type="button" onClick={handleSaveSettings}>Save</button>
            </div>

            <div className="messages-container">
                {successMessages.map((msg) => (
                    <div key={msg.id} className="success-message">
                        {msg.message}
                    </div>
                ))}
                {errors.map((error) => (
                    <div key={error.id} className="error-message">
                        {error.message}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SettingsPage;